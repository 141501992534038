<template>
  <div v-if="isAuthorized">
    <p style="font-weight:bold; color:#635F5D">Car Category Analytics</p>
    <div style="margin-top:50px;">
      <CarCategoryByTime
        title="Count of Car Sold Over Time"
        id="category"
        y-axis-legend="Car Count"
        mode="count"
        line-color="#f74e4e"
        point-color="#ff0000b5"
      ></CarCategoryByTime>
    </div>
  </div>
</template>
<script>
import CarCategoryByTime from "../../components/AnalyticsCharts/CarCategoryByTime/CarCategoryByTime";
export default {
  components: { CarCategoryByTime },
  data() {
    return {
      isAuthorized: false,
    };
  },
  created() {
    if (!localStorage.getItem("jwt")) {
      this.$router.push({ path: "/login" });
    } else {
      this.isAuthorized = true;
    }
  },
  mounted() {},
};
</script>
>
